@import '../../../variables.css';

.header,
.subHeader {
    width: 100%;
    height: var(--ark-header-height);
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: var(--ark-primary-white-figma);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.subHeader {
    top: calc(var(--ark-header-height) - 1px);
    z-index: 120;
}

@media screen and (min-width: var(--ark-small-desktop-1025)) {
    .hidden {
        position: static;
    }

    .hidden .header,
    .hidden .subHeader {
        position: absolute;
        top: 0;
        left: 0;
    }

    .hidden .subHeader {
        top: calc(var(--ark-header-height) - 1px);
    }
}

.mobileOnly {
    display: none;
}

.activeLink {
    background-color: var(--ark-hover-blue-transparent-figma);
    transform: scale(1);
    color: var(--ark-primary-blue-figma);
    font-weight: bold;
}

.header .profileButtons {
    display: inline-flex;
    align-items: center;
    gap: 24px;
}

.header .profileButtons button {
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-font-size);
    border-radius: 24px;
    border: 3px solid transparent;
    background-color: transparent;
}

.header .profileButtons button.primary {
    color: var(--ark-primary-white-figma);
    background-color: var(--ark-main-green-figma);
    border-color: var(--ark-main-green-figma);
}

.header .profileButtons button.outline {
    color: var(--ark-primary-black-figma);
    border-color: currentColor;
}

.categoryLinks {
    display: flex;
    align-items: center;
}

.categoryLinks > a {
    color: var(--ark-primary-black-figma);
    text-decoration: none;
    border-top: 6px solid transparent;
    padding: 16px;
    padding-top: 28px;
    font-weight: var(--ark-font-weight-light);
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-30-line-height);
}

.categoryLinks > a::after {
    display: block;
    content: attr(data-title);
    font-weight: var(--ark-font-weight-bold);
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
}

.categoryLinks > a:hover {
    color: var(--ark-primary-blue-figma);
    font-weight: var(--ark-font-weight-bold);
}

.categoryLinks > a.active {
    color: var(--ark-primary-blue-figma);
    font-weight: var(--ark-font-weight-bold);
    border-top-color: currentColor;
    background-color: var(--ark-hover-blue-transparent-figma);
}

.sidebarButtons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.sidebarButtons button {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 94px;
    padding: 11px 0;
    background-color: transparent;
    border: none;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-font-size);
    transition: color 0.3s;
}

.sidebarButtons button:hover {
    color: var(--ark-primary-blue-figma);
}

.sidebarButtons button:hover svg > *[fill] {
    fill: currentColor;
}

.sidebarButtons button:hover svg > *[stroke] {
    stroke: currentColor;
}

.sidebarButtons button div {
    width: 34px;
    height: 34px;
}

@media (max-width: var(--ark-medium-desktop-figma-1360)) {
    .categoryLinks > a {
        font-size: var(--ark-20-font-size);
    }
}

@media (max-width: var(--ark-medium-desktop-1200)) {
    .categoryLinks > a {
        font-size: var(--ark-18-font-size);
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .header {
        height: var(--ark-mobile-header-height);
        min-width: 100%;
        max-width: 100vw !important;
    }

    .subHeader {
        display: none;
    }

    [__new_header][__zoom_used] [class*="GameTemplate-gameAreaContainerFullScreen"] [class*="GamePageAd-mobileTopAd"] {
        margin-top: var(--ark-mobile-header-height);
    }

    [__new_header][__zoom_used] [class*="GameTemplate-gameAreaContainerFullScreen"] [class*="GameTemplate-gameAreaGameContainer"] {
        max-width: 100%;
        margin-left: 0;
        max-height: calc(100% - var(--ark-mobile-header-height) - 97px);
        margin-top: 0;
    }

    [__new_header][__zoom_used][__adfree] [class*="GameTemplate-gameAreaContainerFullScreen"] [class*="GameTemplate-gameAreaGameContainer"] {
        max-height: calc(100% - var(--ark-mobile-header-height) - var(--side-menu-horizontal-size));
        margin-top: var(--ark-mobile-header-height);
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    [__new_header][__zoom_used] [class*="GameTemplate-gameAreaContainerFullScreen"] [class*="GameTemplate-gameAreaGameContainer"] {
        max-height: unset;
        margin-top: 0;
    }

    [__new_header][__zoom_used][__adfree] [class*="GameTemplate-gameAreaContainerFullScreen"] [class*="GameTemplate-gameAreaGameContainer"] {
        max-width: calc(100% - 5.675rem);
        margin-left: 5.675rem;
        max-height: unset;
    }

    [class*="AppBody-gamePage"] .mobileHidden {
        display: none;
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .header {
        padding: 8px 16px;
    }

    .header .profileButtons {
        gap: 16px;
    }

    .header .profileButtons button {
        height: 42px;
        padding: 0;
        font-size: var(--ark-18-font-size);
        line-height: var(--ark-25-font-size);
    }

    .header .profileButtons > button:first-child {
        width: 102px;
    }

    .header .profileButtons > button:last-child {
        width: 209px;
    }

}

@media (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    [__new_header][__zoom_used][__adfree] [class*="GameTemplate-gameAreaContainerFullScreen"] [class*="GameTemplate-gameAreaGameContainer"] {
        max-height: calc(100% - var(--ark-mobile-header-height));
        margin-top: var(--ark-mobile-header-height);
    }
}

@media (max-width: var(--ark-med-small-mobile-480)) {
    .header {
        padding: 16px;
    }

    .mobileHidden {
        display: none;
    }

    .mobileOnly {
        display: initial;
    }

    .mobileHomeIcon {
        width: 27.7px;
        height: 32px;
    }

    .mobileHomeIcon > * {
        stroke: var(--ark-focus-figma);
    }

    .header .profileButtons {
        gap: 8px;
    }

    .header .profileButtons > button:last-child {
        width: 157px;
    }

    [__new_header][__zoom_used] [class*="GameTemplate-gameAreaContainerFullScreen"] [class*="GamePageAd-mobileTopAd"] {
        margin-top: 0;
    }

    [__new_header][__zoom_used] [class*="GameTemplate-gameAreaContainerFullScreen"] [class*="GameTemplate-gameAreaGameContainer"] {
        max-width: 100%;
        margin-left: 0;
        max-height: calc(100% - 97px);
        margin-top: 0;
    }

    [__new_header][__zoom_used][__adfree] [class*="GameTemplate-gameAreaContainerFullScreen"] [class*="GameTemplate-gameAreaGameContainer"] {
        max-height: 100%;
        margin-top: 0;
    }

}
